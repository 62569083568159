import {
    ArrayField,
    Datagrid,
    DateField,
    FunctionField,
    List,
    LoadingIndicator,
    ReferenceField,
    SimpleShowLayout,
    TextField,
    useGetOne,
    useRecordContext
} from 'react-admin';
import {Button} from "@mui/material";
import {Email} from "@mui/icons-material";
import React from "react";
import CurrencyField from "./CurrencyField";

const EmailGuestsButton = () => {
    const record = useRecordContext()
    const subject = `[Platessa] Dein Törn auf der Platessa - Zahlungserinnerung`;
    const body = `Hallo ${record.firstName},\n\nfür deine Buchung auf der Platessa mit der Buchungsnummer ` +
        `"${record.invoiceNumber}" konnten wir bisher leider keinen Zahlungseingang feststellen.\nBitte prüfe einmal, ` +
        `ob du den Betrag von insgesamt ${record.totalPrice}€ mit dem Verwendungszweck "${record.invoiceNumber}" überwiesen hast.\n` +
        `Wenn ja, schreibe uns bitte einmal, damit wir den Verbleib der Buchung überprüfen können, wenn nicht, bitten wir um zeitnahe Überweisung.` +
        `\n\nMit herzlichen Grüßen\nDein Platessa-Team`;
    const href = `https://webmail.platessa.de/?_task=mail&_action=compose&_to=${record.emailAddress}&_subject=${encodeURI(subject)}&_body=${encodeURI(body)}`;
    return <Button label="E-Mail an Mitsegler" target="_blank" href={href}><Email/></Button>
}

const DepositField = props => {
    const record = useRecordContext();
    const contractDateStr = record.invoiceNumber.matchAll(/(\d{4})-(\d{2})-(\d{2})/g).next().value[0];
    const contractDate = new Date(contractDateStr);
    const dueDate = new Date(contractDateStr);
    dueDate.setDate(contractDate.getDate() + 7);
    console.log(record.totalAmountPayed);
    return <CurrencyField source={props.source}
                          color={record.totalAmountPayed >= record.deposit ? 'green' : new Date() > dueDate ? 'red' : 'black'}/>
}

const TotalPriceField = props => {
    const record = useRecordContext();
    const {data, isLoading} = useGetOne("trips", {id: record.tripId});
    let element = <LoadingIndicator/>
    if (data && !isLoading) {
        const tripStartDate = new Date(data.startTime);
        const dueDate = new Date(data.startTime);
        dueDate.setDate(tripStartDate.getDate() - 14);
        console.log(tripStartDate);
        console.log(dueDate);
        element = <CurrencyField source={props.source}
                                 color={record.totalAmountPayed >= record.totalPrice ? 'green' : new Date() > dueDate ? 'red' : 'black'}/>
    }
    return element;
}


const formatCurrency = (number) => (
    new Intl.NumberFormat('de-DE',
        {style: 'currency', currency: 'EUR'}).format(number)
)

const PaymentShow = () => (
    <SimpleShowLayout>
        <ArrayField source="payments">
            <Datagrid>
                <DateField source="payment.date"/>
                <TextField source="payment.description"/>
                <FunctionField
                    render={record => formatCurrency(record["payment"]["amount"])}
                />
            </Datagrid>
        </ArrayField>
    </SimpleShowLayout>
);

export const PaymentList = () => {
    return (
        <List>
            <Datagrid expand={PaymentShow}>
                <FunctionField
                    source="name"
                    render={record => `${record.firstName} ${record.name}`}
                />
                <TextField source="invoiceNumber"/>
                <DepositField source="deposit"/>
                <TotalPriceField source="totalPrice"/>
                <ReferenceField source="tripId" link={false} label="Törn" reference="trips"/>
                <EmailGuestsButton/>
            </Datagrid>
        </List>
    );
}