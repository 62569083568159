const ContractStatus = [
    {id: 'new', name: 'myroot.status.new'},
    {id: 'publicRequest', name: 'myroot.status.publicRequest'},
    {id: 'contactEstablished', name: 'myroot.status.contactEstablished'},
    {id: 'contractSent', name: 'myroot.status.contractSent'},
    {id: 'contractSigned', name: 'myroot.status.contractSigned'},
    {id: 'depositPayed', name: 'myroot.status.depositPayed'},
    {id: 'fullPricePayed', name: 'myroot.status.fullPricePayed'},
]

export default ContractStatus