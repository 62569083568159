import {Card, CardContent, Grid, Typography} from "@mui/material";
import Calendar from "rc-year-calendar";
import 'rc-year-calendar/locales/rc-year-calendar.de';

import {Loading, Title, useGetList} from "react-admin";
import {useState} from "react";
import tippy from "tippy.js";
import FilteredBookingList from "./FilteredBookingList";

export const Dashboard = () => {
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
    const {data, isLoading} = useGetList('trips', {meta: {year: selectedYear}});
    if (isLoading) {
        return <Loading/>
    }
    const handleDayEnter = (e) => {
        if (e.events.length > 0) {
            let content = '';
            for (const i in e.events) {
                content += '<div class="event-tooltip-content">'
                    + '<div class="event-name" style="color:' + e.events[i].color + '"><b>' + e.events[i].name + '</b></div>'
                    + '<div class="event-details">' + (e.events[i].description ? e.events[i].description : '') + '</div>'
                    + '<div class="event-details">' + (e.events[i].bookedPersons ? e.events[i].bookedPersons : 0) + ' von ' + e.events[i].maxPersons + ' gebucht.' + '</div>'
                    + '</div>';
            }
            const tooltip = tippy(e.element, {
                content: content,
                allowHTML: true,
                arrow: true
            });
            tooltip.show()
        }
    }

    return (
        <Card>
            <Title title="Buchungsübersicht"/>
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item xs={6} sx={{minWidth: '350px'}}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Neue Buchungen</Typography>
                                <FilteredBookingList status="new"/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={6} sx={{minWidth: '350px'}}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Ausstehende Zahlungen</Typography>
                                <FilteredBookingList status="overdue"/>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5">Buchungskalender</Typography>
                                <Calendar
                                    dataSource={data?.map(record => ({
                                        id: record.id,
                                        name: record.name,
                                        startDate: new Date(record.startTime),
                                        endDate: new Date(record.endTime),
                                        description: record.description,
                                        bookedPersons: record.bookedPersons,
                                        maxPersons: record.maxPersons
                                    }))}
                                    language={"de"}
                                    onDayEnter={e => handleDayEnter(e)}
                                    year={selectedYear}
                                    onYearChanged={(data) => setSelectedYear(data.currentYear)}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};