import {
    Datagrid,
    FunctionField,
    ListContextProvider,
    NumberField,
    ReferenceField,
    TextField,
    useGetList,
    useList
} from 'react-admin';
import CurrencyField from "./CurrencyField";
import React from "react";

const FilteredBookingList = ({status}) => {
    const {data, isLoading} = useGetList(
        'bookings',
        {filter: {status: status}},
    );
    const listContext = useList({data, isLoading});
    return (
        <ListContextProvider value={listContext}>
            <Datagrid rowClick="show" bulkActionButtons={false}>
                <ReferenceField source="tripId" link={false} label="Törn" reference="trips"/>
                <FunctionField label="Kontakt" render={record => `${record.firstName} ${record.name}`}/>
                <NumberField source="numberOfPersons" label="Personenzahl"/>
            </Datagrid>
        </ListContextProvider>
    );
};

export default FilteredBookingList
