import {fetchUtils} from 'react-admin';
import {stringify} from 'query-string';
import userPool from "../commons/UserPool";

const apiUrl = process.env.REACT_APP_API_URL;


const httpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
    }

    const user = userPool.getCurrentUser();
    user?.getSession((err, session) => {
        if (session) {
            const idJwt = session.getIdToken().getJwtToken();
            options.headers.set('Authorization', `Bearer ${idJwt}`);
        }
    });

    return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result);
    reader.onerror = reject;

    reader.readAsDataURL(file.rawFile);
});

export default {
    getList: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter), ...params.meta
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json, pageInfo: {
                hasPreviousPage: false, hasNextPage: false,
            },
        }), ({reason}) => {
            return Promise.reject(reason);
        }).catch((e) => {
            console.log(e);
            return Promise.reject(e);
        });
    },

    getOne: (resource, params) => {
        if (params.id) {
            return httpClient(`${apiUrl}/${resource}/${params.id}`).then(({json}) => ({
                data: json,
            }))
        }
        return Promise.reject();
    },

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ids: params.ids}),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({json}) => ({data: json}));
    },

    getManyReference: (resource, params) => {
        const {page, perPage} = params.pagination;
        const {field, order} = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter, [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({headers, json}) => ({
            data: json, total: parseInt(headers.get('content-range').split('/').pop(), 10),
        }));
    },

    create: async (resource, params) => {
        let data = params.data;
        if (resource === 'trips' && data.picture && 'rawFile' in data.picture && data.picture.rawFile instanceof File) {
            data.picture = await Promise.resolve(convertFileToBase64(data.picture))
                .then(base64Picture => ({
                    src: base64Picture, title: data.picture.title,
                }))
                .then(transformedNewPicture => transformedNewPicture)
        }
        return httpClient(`${apiUrl}/${resource}`, {
            method: 'POST', body: JSON.stringify(data),
        }).then(({json}) => ({
            data: {...params.data, id: json.id},
        }))
    },

    update: async (resource, params) => {
        let data = params.data;
        if (resource === 'trips' && data.picture && 'rawFile' in data.picture && data.picture.rawFile instanceof File) {
            data.picture = await Promise.resolve(convertFileToBase64(data.picture))
                .then(base64Picture => ({
                    src: base64Picture, title: data.picture.title,
                }))
                .then(transformedNewPicture => transformedNewPicture)
        }
        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'PUT', body: JSON.stringify(data),
        }).then(({json}) => ({data: json}))
    }, updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT', body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },

    delete: (resource, params) => httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'DELETE',
    }).then(({json}) => ({data: json})),

    deleteMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'DELETE', body: JSON.stringify(params.data),
        }).then(({json}) => ({data: json}));
    },
};