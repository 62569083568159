import {
    BooleanInput,
    Datagrid,
    DateField,
    DateTimeInput,
    Create,
    Edit,
    List,
    NumberField,
    NumberInput,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar,
    ListButton,
    ImageInput,
    ImageField,
    ReferenceInput,
    SelectInput,
    ReferenceField,
    Button,
    useRecordContext,
    useGetList,
    EditButton, LoadingIndicator, useListContext
} from 'react-admin';
import {Box, Card, CardContent, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Typography} from "@mui/material";
import {Email} from "@mui/icons-material";
import ReactCrop from "react-image-crop";
import React, {useRef, useState} from "react";
import CurrencyField from "./CurrencyField";
import {useFormContext, useWatch} from "react-hook-form";
import {AdditionalTerms} from "../constants/AdditionalTerms";
import {RichTextInput} from "ra-input-rich-text";

const EmailAllGuestsButton = () => {
    const record = useRecordContext()
    const {data, isLoading} = useGetList("bookings", {filter: {tripId: record.id}})
    const bcc = data?.map(booking => booking.emailAddress);
    const subject = `[Platessa] Dein Törn "${record.name}"`;
    const body = "Liebe Mitsegelnde,\n\n%% TEXT HIER %%\n\nMit herzlichen Grüßen,\nDein Platessa-Team";
    let element = <LoadingIndicator/>
    if (data && !isLoading) {
        const href = `https://webmail.platessa.de/?_task=mail&_action=compose&_to=mail@platessa.de&_bcc=${bcc.join(',')}&_subject=${encodeURI(subject)}&_body=${encodeURI(body)}`;
        element = <Button label="E-Mail an Mitsegelnde" target="_blank" href={href}><Email/></Button>
    }
    return element
}

const TripAside = () => {
    const {data, isLoading} = useListContext();
    if (isLoading) return null;
    const tripDuration = (data) => {
        const startTime = new Date(data?.startTime);
        const endTime = new Date(data?.endTime);
        return Math.ceil((endTime - startTime) / (1000 * 60 * 60 * 24))
    };
    return (
        <Card sx={{mr: 1, mt: 8, width: 400}}>
            <CardContent>
                <Typography variant="h6">Übersicht</Typography>
                <Typography variant="body2">
                    Gebuchtes Volumen: {data.reduce((sum, trip) => sum + trip.bookedPrice, 0)}€.
                </Typography>
                <Typography variant="body2">
                    Geplantes Volumen: {data.reduce((sum, trip) => sum + trip.totalPrice, 0)}€.
                </Typography>
                <Typography variant="body2">
                    Tage auf See: {data.reduce((sum, trip) => sum + tripDuration(trip), 0)}.
                </Typography>
                <Typography variant="body2">
                    Gäste an Bord: {data.reduce((sum, trip) => sum + trip.bookedPersons, 0)}.
                </Typography>
                <Typography variant="body2">
                    Gäste an Bord ab/an Eckernförde: {data.reduce((sum, trip) => {
                    const bookedPersonsFromEckernfoerde = [trip.startHarbor, trip.endHarbor].includes("Eckernförde") ? trip.bookedPersons : 0;
                    return sum + bookedPersonsFromEckernfoerde
                }, 0)}.
                </Typography>
            </CardContent>
        </Card>
    );
};

export const TripList = () => (<List aside={<TripAside/>}>
    <Datagrid rowClick={false}>
        <TextField source="name"/>
        <TextField source="startHarbor"/>
        <TextField source="endHarbor"/>
        <DateField showTime={true} locales="de-DE" source="startTime"/>
        <DateField showTime={true} locales="de-DE" source="endTime"/>
        <CurrencyField source="totalPrice"/>
        <CurrencyField source="bookedPrice"/>
        <NumberField source="maxPersons"/>
        <NumberField source="remainingPersons"/>
        <ReferenceField source="skipperId" reference="skippers"/>
        <EmailAllGuestsButton/>
        <EditButton/>
    </Datagrid>
</List>);

const DefaultTermsList = ({source}) => {
    const formData = useWatch();
    const formContext = useFormContext();

    return (AdditionalTerms.map(term => (<Box display="flex">
        <Box flex={0.1}>
            <Button onClick={(event) => {
                event.currentTarget.disabled = true;
                formContext.setValue(source, (formData[source] ? formData[source] + " " : "") + term);
            }}>&lt;</Button>
        </Box>
        <Box flex={0.9}>
            <Typography>{term}</Typography>
        </Box>
    </Box>)))
}

const TripForm = () => {
    const [isCropModalOpen, setIsCropModalOpen] = useState(false);

    return (<SimpleForm>
        <Grid container width="100%" spacing={2}>
            <Grid item xs={13}>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth source="name"/>
                    </Box>
                    <Box flex={1} ml="0.5em" mr="0.5em">
                        <SelectInput fullWidth source="type"
                                     choices={[{id: "openCharter", name: "myroot.tripType.openCharter"}, {
                                         id: "groupCharter", name: "myroot.tripType.groupCharter"
                                     }]}/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <ReferenceInput fullWidth source="skipperId" reference="skippers"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth source="startHarbor"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <TextInput fullWidth source="endHarbor"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <DateTimeInput fullWidth source="startTime"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <DateTimeInput fullWidth source="endTime"/>
                    </Box>
                </Box>
                <RichTextInput fullWidth source="description" multiline/>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth source="additionalTerms" multiline/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <DefaultTermsList source="additionalTerms"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <NumberInput fullWidth required source="totalPrice"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <NumberInput fullWidth required source="maxPersons"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <BooleanInput fullWidth source="includesHarborFees"/>
                    </Box>
                    <Box flex={1} ml="0.5em" mr="0.5em">
                        <BooleanInput fullWidth source="includesBerth"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <BooleanInput fullWidth source="includesMeals"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <BooleanInput fullWidth source="businessOperation"/>
                    </Box>
                    <Box flex={1} ml="0.5em" mr="0.5em">
                        <BooleanInput fullWidth source="public"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <BooleanInput fullWidth source="publicPrice"/>
                    </Box>
                </Box>
                <ImageInput source="picture" accept="image/*" minSize={5000} maxSize={5000000}
                            options={{onDropAccepted: () => setIsCropModalOpen(true)}}>
                    <>
                        <ImageField source="src" title="title" fullWidth/>
                        <ReactCropImageField fullWidth source="src" isCropModalOpen={isCropModalOpen}
                                             setIsCropModalOpen={setIsCropModalOpen} title="title"/>
                    </>
                </ImageInput>
            </Grid>
        </Grid>

    </SimpleForm>)
}

const ReactCropImageField = ({isCropModalOpen, setIsCropModalOpen}) => {
    const imageRef = useRef();
    const formData = useWatch();
    const formContext = useFormContext();
    const [state, setState] = useState({
        src: null, croppedImageUrl: undefined, blob: undefined, fileUrl: undefined, crop: {
            unit: '%', x: 12.5, height: 30, width: 75, aspect: 4 / 3,
        }
    });

    const makeClientCrop = (imageRef, crop) => {
        if (imageRef && crop.width && crop.height) {
            getCroppedImg(imageRef.current, crop, formData.picture.title).then(croppedPicture => formContext.setValue("picture", {
                title: formData.picture.title, rawFile: new File([croppedPicture.blob], formData.picture.title, {
                    type: "image/png", lastModified: new Date().getTime()
                }), src: croppedPicture.fileUrl
            }));
        }
    };

    /**
     * Transforms the original image to a cropped copy by applying the cropping parameters
     */
    const getCroppedImg = (image, crop, fileName) => {
        const width = 400;
        const height = 300;
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');

        if (ctx) {
            ctx.drawImage(image, crop.x * scaleX, crop.y * scaleY, crop.width * scaleX, crop.height * scaleY, 0, 0, width, height);
        }

        return new Promise((resolve) => canvas.toBlob(blob => {
            blob['name'] = fileName;
            const fileUrl = window.URL.createObjectURL(blob);
            resolve({fileUrl: fileUrl, blob: blob});
        }, 'image/png'));
    };

    const onImageLoaded = event => {
        imageRef.current = event.target;
    };
    const onCropChange = (crop) => {
        setState(prevState => ({
            ...prevState, crop: crop
        }));
    };

    const handleCancel = () => {
        setIsCropModalOpen(false);
        formContext.setValue("picture", null);
    }
    const handleSave = () => {
        makeClientCrop(imageRef, state.crop);
        setIsCropModalOpen(false);
    }

    return (<Dialog open={isCropModalOpen} onClose={handleCancel}>
        <DialogTitle>Bildausschnitt wählen</DialogTitle>
        <DialogContent>
            <ReactCrop keepSelection={true} crop={state.crop} aspect={4 / 3} onChange={c => onCropChange(c)}>
                <img alt="" style={{maxHeight: "400px", maxWidth: "400px"}} onLoad={onImageLoaded}
                     src={formData.picture.src}/>
            </ReactCrop>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCancel}>Abbrechen</Button>
            <Button onClick={handleSave}>Speichern</Button>
        </DialogActions>
    </Dialog>)
}

const TripEditActions = () => (<TopToolbar>
    <ListButton/>
</TopToolbar>);

export const TripEdit = () => (<Edit actions={<TripEditActions/>}>
    <TripForm/>
</Edit>);

export const TripCreate = () => (<Create redirect="list">
    <TripForm/>
</Create>);