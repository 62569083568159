import {
    ArrayField,
    ArrayInput,
    BooleanInput,
    Button,
    Confirm,
    Create,
    Datagrid,
    DateField,
    Edit, FunctionField,
    List,
    ListButton,
    NumberField,
    NumberInput,
    ReferenceField,
    ReferenceInput,
    required,
    SelectInput,
    Show,
    SimpleForm,
    SimpleFormIterator,
    TabbedShowLayout,
    TextField,
    TextInput,
    TopToolbar,
    useCreate,
    useGetIdentity,
    useGetOne,
    useRecordContext, useTranslate
} from 'react-admin';
import {Box, Grid, Typography} from '@mui/material';
import {useFormContext, useWatch} from 'react-hook-form';
import React, {useEffect, useState} from "react";
import CurrencyField from "./CurrencyField";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ContractStatus from "../constants/ContractStatus";

const range = (start, end) => Array.from({length: (end - start)}, (v, k) => k + start);
const currentDate = new Date();

const bookingFilters = [
    <ReferenceInput source="tripId" reference="trips" alwaysOn/>,
    <SelectInput source="year" choices={range(2023, currentDate.getFullYear() + 3).map(year => ({
        id: year.toString(), name: year.toString()
    }))} alwaysOn/>,
    <SelectInput source="status" choices={ContractStatus} alwaysOn/>
]

export const BookingDatagrid = () => {
    const translate = useTranslate();

    return (
        <Datagrid rowClick="show">
            <ReferenceField source="tripId" link={false} reference="trips"/>
            <FunctionField label="Status" render={record => translate(`myroot.status.${record.status}`)}/>
            <TextField source="firstName"/>
            <TextField source="name"/>
            <NumberField source="numberOfPersons"/>
            <CurrencyField source="totalPrice"/>
            <NumberField source="additionalTermsForBooking"/>
        </Datagrid>
    )
}

export const BookingList = () => {
    return (<List filters={bookingFilters}>
        <BookingDatagrid/>
    </List>);
}

const NumberOfPersonsInput = props => {
    const {tripId} = useWatch();
    const {data} = useGetOne("trips", {id: tripId});
    const formContext = useFormContext();
    const [isDisabled, setIsDisabled] = useState(false);
    useEffect(() => {
        if (data?.type === "groupCharter") {
            setIsDisabled(true);
            formContext.setValue("numberOfAdults", data.maxPersons)
        }
    }, [data])
    return (<NumberInput {...props} disabled={isDisabled}/>)
}

const TotalPriceInput = props => {
    const {
        numberOfAdults, numberOfChildren, numberOfAdultMembers, numberOfChildMembers, tripId, customPrice
    } = useWatch();
    const formContext = useFormContext();
    const {data} = useGetOne("trips", {id: tripId});

    const priceList = {
        groupCharter: {
            1: {price: 1120, weekendPrice: 1340},
            2: {price: 1680},
            3: {price: 2085},
            4: {price: 2780},
            5: {price: 2850},
            6: {price: 3420},
            7: {price: 3990}
        }, openCharter: {}
    }

    const isWeekend = date => {
        return date.getDay() === 6 || date.getDay() === 0;
    }

    useEffect(() => {
        const startTime = new Date(data?.startTime);
        const endTime = new Date(data?.endTime);
        const tripDuration = Math.ceil((endTime - startTime) / (1000 * 60 * 60 * 24));
        let price = 0;
        if (data?.type === "groupCharter") {
            if (isWeekend(endTime) && isWeekend(startTime)) {
                price = priceList.groupCharter[tripDuration].weekendPrice
            } else {
                price = priceList.groupCharter[tripDuration].price
            }
        }
        formContext.setValue(props.source, price);
    }, [numberOfAdults, numberOfChildren, numberOfAdultMembers, numberOfChildMembers, tripId, data]);
    return (<NumberInput {...props} disabled={!customPrice}/>);
};

const DepositInput = props => {
    const {
        totalPrice, customPrice
    } = useWatch();
    const formContext = useFormContext();

    useEffect(() => {
        formContext.setValue(props.source, Math.floor(totalPrice * 100 * 0.25) / 100);
    }, [totalPrice]);
    return (<NumberInput {...props} disabled={!customPrice}/>)
}

const BookingForm = () => {
    const {data} = useGetIdentity();

    return (<SimpleForm>
        <Grid container width="100%" spacing={2}>
            <Grid item xs={8}>
                <Typography variant="h6" gutterBottom>
                    Details zum Törn
                </Typography>
                <ReferenceInput source="tripId" reference="trips" validate={required()}/>
                <TextInput source="additionalTermsForBooking" fullWidth multiline/>
                <Typography variant="h6" gutterBottom>
                    Kontakt Details
                </Typography>
                <SelectInput source="salutation" validate={required()}
                             choices={[{id: 'male', name: 'myroot.gender.male'}, {
                                 id: 'female', name: 'myroot.gender.female'
                             }, {id: 'diverse', name: 'myroot.gender.diverse'},]}/>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth source="firstName" validate={required()}/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <TextInput fullWidth source="name" validate={required()}/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth source="phoneNumber"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <TextInput type="email" fullWidth source="emailAddress"/>
                    </Box>
                </Box>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TextInput fullWidth required source="contractPartner"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <TextInput fullWidth source="company"/>
                    </Box>
                </Box>
                <Typography variant="h6" gutterBottom>
                    Buchungsdaten
                </Typography>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <NumberOfPersonsInput source="numberOfAdults" fullWidth defaultValue={1}/>
                    </Box>
                    <Box flex={1} mr="0.5em" ml="0.5em">
                        <NumberOfPersonsInput source="numberOfChildren" fullWidth defaultValue={0}/>
                    </Box>
                    <Box flex={1} mr="0.5em" ml="0.5em">
                        <NumberOfPersonsInput source="numberOfAdultMembers" fullWidth defaultValue={0}/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <NumberOfPersonsInput source="numberOfChildMembers" fullWidth defaultValue={0}/>
                    </Box>
                </Box>

                <BooleanInput source="customPrice" default={false}/>
                <Box display="flex">
                    <Box flex={1} mr="0.5em">
                        <TotalPriceInput fullWidth source="totalPrice"/>
                    </Box>
                    <Box flex={1} ml="0.5em">
                        <DepositInput source="deposit" fullWidth/>
                    </Box>
                </Box>
                <SelectInput source="status" defaultValue="new" choices={ContractStatus}/>
                <ArrayInput fullWidth source="comments">
                    <SimpleFormIterator inline>
                        <TextInput source="author" defaultValue={data ? data.id : null}/>
                        <TextInput source="text" fullWidth multiline/>
                    </SimpleFormIterator>
                </ArrayInput>
            </Grid>
        </Grid>

    </SimpleForm>)
}

const BookingEditActions = () => (<TopToolbar>
    <ListButton/>
</TopToolbar>);

export const BookingEdit = () => (<Edit actions={<BookingEditActions/>}>
    <BookingForm/>
</Edit>);

export const BookingCreate = () => (<Create redirect="list">
    <BookingForm/>
</Create>);

const CreateContractButton = () => {
    const record = useRecordContext();
    const [open, setOpen] = useState(false);

    const [create, {isLoading}] = useCreate(
        'contracts',
        {data: {bookingId: record.id, type: "eSignatures"}}
    );

    const handleClick = () => setOpen(true);
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        create();
        setOpen(false);
    };

    return (
        <>
            <Button label="myroot.button.createContract" disabled={record?.contract?.id} onClick={handleClick}/>
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Vertrag für ${record && record.firstName} ${record && record.name} anlegen`}
                content="Soll der Vertrag mit den folgenden Daten angelegt werden?"
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};

const ContractField = props => {
    const record = useRecordContext();
    const {data} = useGetOne("contracts", {id: record?.contract?.id});
    const onDownload = () => {
        const link = document.createElement("a");
        link.download = data?.data?.contract_pdf_url;
        link.href = data?.data?.contract_pdf_url;
        link.target = "_blank"
        link.click();
    };

    const signers = data?.data?.signers;

    const statusMap = {
        email_contract_sent: "E-Mail verschickt",
        contract_viewed: "Vertrag angesehen",
        sign_contract: "Vertrag unterzeichnet",
        email_final_contract_sent: "Vertrag als PDF versendet"
    }

    return (
        <>
            <Grid container spacing={2}>
                {signers?.map((signer, i) => (
                    <Grid xs={6}>
                        <Timeline position={i === 1 ? "right" : "left"}>
                            <Typography align="center" variant="body1">
                                {signer.name}
                            </Typography>
                            {signer.events.map(element => (
                                <TimelineItem>
                                    <TimelineSeparator>
                                        <TimelineDot color="success"/>
                                        <TimelineConnector/>
                                    </TimelineSeparator>
                                    <TimelineContent>
                                        <Typography variant="body1">
                                            {statusMap[element.event]}
                                        </Typography>
                                        <Typography color="gray" variant="body2">
                                            {(new Date(element.timestamp)).toLocaleDateString()} - {(new Date(element.timestamp)).toLocaleTimeString()}
                                        </Typography>
                                    </TimelineContent>
                                </TimelineItem>
                            ))}
                        </Timeline>
                    </Grid>
                ))
                }
            </Grid>
            <Button label="PDF herunterladen" disabled={!data?.data?.contract_pdf_url} onClick={onDownload}/>
        </>
    )
}

export const BookingShow = () => {
    const translate = useTranslate();
    return (
        <Show>
            <TabbedShowLayout>
                <TabbedShowLayout.Tab label="Buchungspartner">
                    <FunctionField label="Status" render={record => translate(`myroot.status.${record.status}`)}/>
                    <TextField source="firstName"/>
                    <TextField source="name"/>
                    <TextField source="contractPartner"/>
                    <TextField source="company"/>
                    <TextField source="emailAddress"/>
                    <TextField source="phoneNumber"/>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Törn Daten">
                    <ReferenceField source="tripId" reference="trips"/>
                    <TextField source="additionalTermsForBooking"/>
                    <CurrencyField source="totalPrice"/>
                    <CurrencyField source="deposit"/>
                    <NumberField source="numberOfPersons"/>
                    <NumberField source="numberOfAdults"/>
                    <NumberField source="numberOfChildren"/>
                    <NumberField source="numberOfAdultMembers"/>
                    <NumberField source="numberOfChildMembers"/>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Vertrag">
                    <TextField source="invoiceNumber"/>
                    <CreateContractButton/>
                    <ContractField/>
                </TabbedShowLayout.Tab>
                <TabbedShowLayout.Tab label="Kommentare">
                    <ArrayField source="comments" label={null}>
                        <Datagrid bulkActionButtons={false}>
                            <DateField source="datePosted"/>
                            <TextField source="author"/>
                            <TextField source="text"/>
                        </Datagrid>
                    </ArrayField>
                </TabbedShowLayout.Tab>
            </TabbedShowLayout>
        </Show>
    );
}