import {Admin, Resource} from "react-admin";
import restDataProvider from "./data/RestDataProvider";
import {TripEdit, TripList, TripCreate} from "./components/Trips";
import {i18nProvider} from "./i18nProvider";
import {CognitoAuthProvider, Login} from "ra-auth-cognito";
import userPool from "./commons/UserPool";
import {SkipperCreate, SkipperEdit, SkipperList} from "./components/Skippers";
import {Surfing, Description, Sailing, Payment} from "@mui/icons-material";
import {BookingCreate, BookingEdit, BookingList, BookingShow} from "./components/Bookings";
import {Dashboard} from "./components/Dashboard";
import {PaymentList} from "./components/Payments";

const authProvider = CognitoAuthProvider(userPool);

const App = () => (
    <Admin
        authProvider={authProvider}
        dataProvider={restDataProvider}
        i18nProvider={i18nProvider}
        loginPage={Login}
        dashboard={Dashboard}
    >
        <Resource
            name="trips"
            list={TripList}
            edit={TripEdit}
            create={TripCreate}
            icon={Sailing}
            recordRepresentation={(record) => `${record.name} - ${new Date(record.startTime).toLocaleDateString()}-${new Date(record.endTime).toLocaleDateString()}`}
        />
        <Resource name="contracts"/>
        <Resource
            name="bookings"
            list={BookingList}
            create={BookingCreate}
            edit={BookingEdit}
            show={BookingShow}
            icon={Description}
            recordRepresentation={(record) => `${record.firstName} ${record.name}`}
        />
        <Resource
            name="skippers"
            list={SkipperList}
            create={SkipperCreate}
            edit={SkipperEdit}
            icon={Surfing}
            recordRepresentation={(record) => `${record.firstName} ${record.name}`}
        />
        <Resource name="contracts"/>
        <Resource name="payments" list={PaymentList} icon={Payment}/>
    </Admin>
);

export default App;