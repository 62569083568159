import {
    BooleanField,
    BooleanInput,
    Create,
    Datagrid,
    Edit,
    List,
    ListButton,
    required,
    SelectInput,
    SimpleForm,
    TextField,
    TextInput,
    TopToolbar
} from 'react-admin';
import {Box, Grid} from "@mui/material";

export const SkipperList = () => (<List>
    <Datagrid rowClick="edit">
        <TextField source="firstName"/>
        <TextField source="name"/>
        <TextField source="phoneNumber"/>
        <TextField source="emailAddress"/>
        <BooleanField source="active"/>
    </Datagrid>
</List>);


const SkipperEditActions = () => (<TopToolbar>
    <ListButton/>
</TopToolbar>);
export const SkipperCreate = () => (<Create redirect="list">
    <SkipperForm/>
</Create>);

export const SkipperEdit = () => (<Edit actions={<SkipperEditActions/>}>
    <SkipperForm/>
</Edit>)
const SkipperForm = () => (<SimpleForm>

    <Grid container width="100%" spacing={2}>
        <Grid item xs={8}>
            <SelectInput source="salutation" validate={required()}
                         choices={[
                             {id: 'male', name: 'Male'},
                             {id: 'female', name: 'Female'},
                             {id: 'diverse', name: 'Diverse'},
                         ]}/>
            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth validate={required()} source="firstName"/>
                </Box>
                <Box flex={1} ml="0.5em">
                    <TextInput fullWidth validate={required()} source="name"/>
                </Box>
            </Box>
            <Box display="flex">
                <Box flex={1} mr="0.5em">
                    <TextInput fullWidth validate={required()} source="phoneNumber"/>
                </Box>
                <Box flex={1} ml="0.5em">
                    <TextInput fullWidth validate={required()} type="email" source="emailAddress"/>
                </Box>
            </Box>
            <BooleanInput source="active"/>
        </Grid>
    </Grid>

</SimpleForm>)
