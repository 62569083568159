import germanMessages from 'ra-language-german';

germanMessages.ra.configurable = {};
germanMessages.ra.configurable.customize = "Anpassen"

const de = {
    ...germanMessages,
    resources: {
        trips: {
            name: 'Törn |||| Törns',
            fields: {
                startHarbor: 'Start-Hafen',
                endHarbor: 'End-Hafen',
                startTime: 'Startzeit',
                endTime: 'Endzeit',
                totalPrice: 'Gesamtpreis',
                businessOperation: 'Geschäftsbetrieb',
                maxPersons: 'Max. Gäste',
                includesHarborFees: 'Inkl. Hafengebühren',
                includesBerth: 'Inkl. Kojenplatz',
                includesMeals: 'Inkl. Verpflegung',
                public: 'Veröffentlicht',
                publicPrice: 'Preis veröffentlichen',
                description: 'Beschreibung',
                additionalTerms: 'Besondere Absprachen',
                bookedPrice: 'Gebuchtes Volumen',
                remainingPersons: 'Verbleibende Plätze',
                type: 'Art',
                picture: 'Bild'
            },
        },
        bookings: {
            name: 'Buchung |||| Buchungen',
            fields: {
                tripId: 'Törn',
                additionalTermsForBooking: 'Zusätzliche Vereinbarungen',
                salutation: 'Anrede',
                firstName: 'Vorname',
                phoneNumber: 'Telefon',
                emailAddress: 'E-Mail',
                contractPartner: 'Vertragspartner',
                company: 'Firma',
                numberOfPersons: 'Gebuchte Personenzahl',
                numberOfAdults: 'Anzahl Erwachsene',
                numberOfChildren: 'Anzahl Kinder',
                numberOfAdultMembers: 'Anzahl Erwachsene (Mitglieder)',
                numberOfChildMembers: 'Anzahl Kinder (Mitglieder)',
                totalPrice: 'Gesamtpreis',
                deposit: 'Anzahlung',
                comments: 'Kommentare',
                status: 'Status',
                customPrice: 'Abweichender Preis',
                year: 'Jahr',
                invoiceNumber: 'Rechnungsnummer',
            }
        },
        skippers: {
            name: 'Skipper |||| Skipper',
            fields: {
                salutation: 'Anrede',
                name: 'Name',
                firstName: 'Vorname',
                emailAddress: 'E-Mail Adresse',
                phoneNumber: 'Telefonnummer',
                active: 'Aktiv',
            }
        }
    },
    myroot: {
        gender: {
            male: 'Herr',
            female: 'Frau',
            diverse: 'Divers'
        },
        status: {
            new: 'Neu',
            publicRequest: 'Online Buchungsanfrage',
            contactEstablished: 'Kontakt hergestellt',
            contractSent: 'Vertrag versendet',
            contractSigned: 'Vertrag unterschrieben',
            depositPayed: 'Anzahlung gezahlt',
            fullPricePayed: 'Voll bezahalt',
        },
        tripType: {
            openCharter: 'Einzelbucher Törn',
            groupCharter: 'Gruppen Törn'
        },
        button: {
            createContract: 'Vertrag anlegen'
        }
    }
};

export default de;