import {FunctionField} from "react-admin";


const formatCurrency = (number) => (
    new Intl.NumberFormat('de-DE',
        {style: 'currency', currency: 'EUR'}).format(number)
)

const CurrencyField = props => (<FunctionField
        {...props} render={record => formatCurrency(record[props.source])}
    />)

export default CurrencyField